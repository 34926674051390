<template>
  <div class="baseLayout">
    <Header />
    <div class="banner">
      <div class="title">
        <!-- <span>{{title}}</span> -->
        <!-- <p>data.iocean.cn</p> -->
      </div>
    </div>
    <div class="main">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data(){
    return{
      title:''
    }
  },
  watch:{
    $route(to, from){
      this.title = to.meta.title
    }
  },
  mounted(){
    this.title = this.$route.meta.title
  }
};
</script>

<style lang='less' scoped>
.baseLayout {
  background: #eef8ff;
}
.banner {
  width: 100%;
  height: 90px;
  background: url("../assets/forecast/banner.jpg") no-repeat;
  background-size: cover;
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding-top: 78px;

    span {
      font-size: 40px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
      margin-top: 5px;
      font-weight: bold;
      color: rgba(255,255,255,.6);
    }
  }
}


.main {
  width: 1200px;
  margin: -70px auto 0 auto;
  min-height: calc(100vh - 260px);
}
</style>