<template>
    <div class="footer">
        Copyright © 2006-2023 iocean all rights reserved 版权所有
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1b2d52;
    width: 100%;
    height: 80px;
    color: #fff;
    font-size: 14px;
}
</style>