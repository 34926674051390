<template>
    <div class="header">
        <div class="top">
            <font>海洋数据</font>
            <font>data.iocean.cn</font>
        </div>
        <div class="user">
            <div class="name" v-if="nickName">{{nickName}}</div>
            <img src="../../assets/image/common/avatar.jpg" />
        </div>
        <div class="nav">
            <div class="menu" v-for="item in list" :key="item.path" @click="goto(item.path)" :class="matchedPath == item.path ? 'active' : ''">
                {{ item.title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            matchedPath: "",
            nickName: ''
        };
    },
    mounted: function () {
        let routers = this.$router.matcher.getRoutes();
        routers = routers.filter((item) => {
            return (
                item.path &&
                item.path.includes("/main") &&
                item.path.split("/").length == 3
            );
        });
        routers = routers.map((item) => {
            return {
                path: item.path,
                title: item.meta?.title,
            };
        });
        this.list = routers;
        this.$route.matched.map((item) => {
            this.isMatched(item.path);
        });
        this.nickName = this.$storage.userInfo?.nickName
    },
    watch: {
        $route: function (val, old) {
            val.matched.map((item) => {
                this.isMatched(item.path);
            });
        },
    },
    methods: {
        goto(path) {
            this.$router.push(path);
        },
        isMatched(path) {
            this.list.map((item) => {
                if (item.path == path) {
                    this.matchedPath = path;
                }
            });
        },
    },
};
</script>

<style lang='less' scoped>
.header {
    width: 100%;
    height: 90px;
    position: relative;
    background: url(../../assets/image/common/bg.gif) center repeat-x;
    display: flex;
    align-items: center;
    .top {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 30px;
        img {
            width: 40px;
            height: 40px;
            border: 1px solid #fff;
            border-radius: 4px;
            margin: 0 5px;
        }
        font {
            color: #fff;
            font-size: 28px;
            font-weight: 700;
            margin: 0 5px;
        }
        font:last-child {
            margin-top: 10px;
        }
    }
    .user {
        position: absolute;
        top: 15px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        > .name {
            font-size: 14px;
            color: #fff;
            margin-right: 8px;
        }
        > img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
        }
    }
    .nav {
        width: 900px;
        margin: 0 auto;
        height: 90px;
        display: flex;
        justify-content: space-around;
        .menu {
            font-size: 18px;
            color: #fff;
            line-height: 90px;
            cursor: pointer;
            position: relative;
        }
        .menu.active {
            font-size: 20px;
            font-weight: 700;
        }
        .menu.active::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            background: #00a2e9;
        }
    }
}
</style>